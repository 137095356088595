<template>
  <v-row>
    <v-col cols="12">
      <v-card v-if="confirming">
        <v-card-text>
          Confirming password...
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      confirming: true
    };
  },
  computed: {
    userId() {
      return this.$route.params.userId;
    },
    token() {
      return this.$route.params.token;
    }
  },
  mounted() {
    this.confirmPassword();
  },
  methods: {
    confirmPassword() {
      this.axios
        .post("auth/confirm-new-password", {
          id: this.userId,
          token: this.token
        })
        .then(() => {
          this.confirming = false;

          this.$store.dispatch("app/showMainAlert", {
            type: "success",
            message: this.$t("app.password_changed_successfully")
          });
        })
        .catch(() => {
          this.confirming = false;

          this.$store.dispatch("app/showMainAlert", {
            type: "error",
            message: this.$t("app.password_changing_url_is_invalid")
          });
        });
    }
  }
};
</script>
